import React from "react"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import CTAButton from "./ctaButton"
import BlockContent from "./page-builder/richText"

import styles from "./postCta.module.scss"

const PostCTA = ({ title, body, links, image, className }) => {
  const isImage = !!image?.image?.asset?.fluid
  const classes = isImage ? "gridItem large-6 xlarge-offset-1" : "gridItem"

  const ctaClass = cx(styles.cta, {
    [styles.ctaCentered]: !isImage,
    [className]: className,
  })

  return (
    <section className={ctaClass}>
      <div className="grid">
        <div className={classes}>
          {title && <h1 className={styles.ctaTitle}>{title}</h1>}
          {body && <BlockContent blocks={body}></BlockContent>}
          {links && (
            <div className={styles.ctaLinks}>
              {links.link.map(link => (
                <CTAButton key={link._key} blocks={link} />
              ))}
            </div>
          )}
        </div>
        {isImage && (
          <div
            className="gridItem large-6 xlarge-4"
            style={{ alignSelf: "center" }}
          >
            <Img
              fluid={image.image.asset.fluid}
              objectFit="contain"
              alt={image.alt}
              className={styles.ctaImage}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default PostCTA
