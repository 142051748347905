import React from "react"
import cx from "classnames"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import Sidebar from "../../components/sidebar"
import PostCTA from "../../components/postCta"
import BlockContent from "../../components/page-builder/richText"
import Button from "../../components/button"
import LatestBlocksSection from "../../components/page-builder/latestBlocksSection"

import styles from "./caseStudy.module.scss"

export const query = graphql`
  query($slug: String) {
    sanityCaseStudy(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      title
      category {
        slug {
          current
        }
        title
      }
      logo {
        image {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        alt
      }
      sidebar {
        tableRow {
          _key
          rowTitle
          rowValue
        }
      }
      externalLink {
        label
        link
      }
      download {
        docTitle
        document {
          asset {
            url
            originalFilename
          }
        }
      }
      _rawCta(resolveReferences: { maxDepth: 2 })
      cta {
        ctaImage {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
      _rawBody
    }
  }
`

const CaseStudyTemplate = props => {
  const { data, location } = props
  const post = data && data.sanityCaseStudy
  const category = post.category.slug.current
  const title = post.seo && post.seo.metaTitle ? post.seo.metaTitle : post.title
  const description =
    post.seo && post.seo.description ? post.seo.description : null
  const ogImage =
    post.seo &&
    post.seo.openGraphImage &&
    post.seo.openGraphImage.asset !== null
      ? post.seo.openGraphImage.asset.url
      : null
  const showSidebar =
    post.logo || post.sidebar || post.download || post.externalLink

  const caseStudyContentClass = cx(styles.caseStudyContent, {
    [styles.caseStudyContent__withSidebar]: showSidebar,
  })

  return (
    <>
      <Layout>
        <Helmet>
          {post.seo && post.seo.noIndex && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <SEO title={title} description={description} image={ogImage} />
        <Breadcrumbs className={styles.caseStudyBreadcrumbs} location={location}>
          <Link to="/">Home</Link>
          <Link to="/resources/">Resources</Link>
          <Link to={`/resources/?product=${category}`}>
            {post.category.title}
          </Link>
          <p>{post.title}</p>
        </Breadcrumbs>
        <section className={caseStudyContentClass}>
          <div className={styles.caseStudyBlockContent}>
            <h1 className={styles.caseStudyTitle}>{post.title}</h1>
            <BlockContent blocks={post._rawBody} />
          </div>
          {showSidebar && (
            <Sidebar
              className={styles.sidebar}
              sidebarChildrenClass={styles.sidebarChildren}
            >
              <>
                <div className={styles.sidebarTable}>
                  {post.sidebar &&
                    post.sidebar.tableRow.map(row => (
                      <div key={row._key}>
                        <h3>{row.rowTitle}</h3>
                        <p>{row.rowValue}</p>
                      </div>
                    ))}
                  {post.download && post.download.document.asset && (
                    <div>
                      <h3>Download</h3>
                      <p>
                        <a
                          href={post.download.document.asset.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {post.download.docTitle
                            ? post.download.docTitle
                            : post.download.document.asset.originalFilename}
                        </a>
                      </p>
                    </div>
                  )}
                  {post.externalLink?.link && (
                    <div>
                      <p>
                        <a
                          href={post.externalLink.link}
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                        >
                          {post.externalLink.label
                            ? post.externalLink.label
                            : post.externalLink.link}
                        </a>
                      </p>
                    </div>
                  )}
                </div>
                <Button
                  className={styles.sidebarButton}
                  type="secondary"
                  to={`products/${category}/request-a-demo/`}
                  label={`Try PeopleFluent ${post.category.title}`}
                />
              </>
            </Sidebar>
          )}
        </section>
        {post._rawCta && (
          <PostCTA
            title={post._rawCta.ctaHeader}
            body={post._rawCta.ctaBody}
            links={post._rawCta.ctaButton}
            image={post.cta.ctaImage}
          />
        )}
        <LatestBlocksSection
          blocksTypes="resources"
          category={category}
          title={`${category} Software Resources`}
        />
      </Layout>
    </>
  )
}

export default CaseStudyTemplate
