import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import cx from "classnames"

import styles from "./sidebar.module.scss"

const Sidebar = props => {
  const sidebarClass = cx(styles.sidebar, {
    [props.className]: props.className,
  })

  const sidebarChildrenClass = cx(styles.sidebarChildren, {
    [props.sidebarChildrenClass]: props.sidebarChildrenClass,
  })

  return (
    <div className={sidebarClass}>
      {props.image && (
        <Img
          className={styles.sidebarLogo}
          fluid={props.image}
          objectFit="contain"
          alt={props.alt}
          objectPosition="0 50%"
        />
      )}
      <div className={sidebarChildrenClass}>{props.children}</div>
    </div>
  )
}

export default Sidebar
